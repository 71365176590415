// extracted by mini-css-extract-plugin
export var cSummaryDiscount = "checkout-module--cSummaryDiscount--He-QI";
export var checkoutAddress = "checkout-module--checkoutAddress--OD1vk";
export var checkoutAddressToggle = "checkout-module--checkoutAddressToggle--9kBIP";
export var checkoutStep = "checkout-module--checkoutStep--TXpyE";
export var checkoutStepButton = "checkout-module--checkoutStepButton--bWwiN forms-module--button--ZipCt";
export var checkoutStepContent = "checkout-module--checkoutStepContent--YdGJ6";
export var checkoutStepFooter = "checkout-module--checkoutStepFooter--XRddS";
export var checkoutStepIsClickable = "checkout-module--checkoutStepIsClickable--LrCl0";
export var checkoutStepIsOpen = "checkout-module--checkoutStepIsOpen--vbZE0";
export var checkoutStepTick = "checkout-module--checkoutStepTick--jK02t";
export var checkoutStepTitle = "checkout-module--checkoutStepTitle--j-Xxf";
export var checkoutWrap = "checkout-module--checkoutWrap--NlKho";
export var loadingSvg = "checkout-module--loadingSvg--g4tDW";
export var shippingOptionMessage = "checkout-module--shippingOptionMessage--VMEFS";
export var spin = "checkout-module--spin--dwFpl";